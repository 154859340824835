
import Vue from 'vue'
import SaLink from '~/components/_general/SaLink.vue'
import SiteCopyrightText from '~/components/site/CopyrightText.vue'

export default Vue.extend({
  name: 'SiteFeaturedCopyrightElement',
  components: { SiteCopyrightText, SaLink },
  props: {
    showMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      siteBaseUrl: 'https://www.sermonaudio.com/',
    }
  },
})
