
import Vue from 'vue'

export default Vue.extend({
  name: 'SiteCopyrightText',
  props: {
    showCopyrightText: {
      type: Boolean,
      default: false,
    },
    showPeriod: {
      type: Boolean,
      default: true,
    },
  },
})
