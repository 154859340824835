
import Vue from 'vue'

export default Vue.extend({
  name: 'SiteFeaturedSpotlightDots',
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
  },
})
